import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p>{`Jedinečné príležitosti si zaslúžia jedinečné miesto. A takýmto miestom sú práve Studničky. Intímne prostredie a všade
navôkol len krásna príroda. Jednoducho ideálne miesto na rodinné oslavy či svadby.`}</p>
      <p>{`Radi sa staneme súčasťou vašej udalosti a pomôžeme vám s prípravami. V reštaurácii Studničky pre vás pripravíme výborné
špeciality počas celého podujatia a v prípade záujmu máme pre vašich hostí aj ubytovanie. Kapacita reštaurácie je až 100
osôb a poskytne vám aj priestor pre tanečný parket.`}</p>
      <p><strong parentName="p">{`Máte záujem o svadbu alebo oslavu u nás?`}</strong></p>
      <p>{`Neváhajte nás `}<a parentName="p" {...{
          "href": "/kontakt/"
        }}>{`kontaktovať`}</a>{`.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGehjRUYR//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIRAxAx/9oACAEBAAEFAo4xI4Riqqtf/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AYj/xAAYEAADAQEAAAAAAAAAAAAAAAABEFERMf/aAAgBAQAGPwIQri2L/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERIVFBYXH/2gAIAQEAAT8hZKi6ClZv1EWXTPEVCSuVg6vd3T//2gAMAwEAAgADAAAAEFD/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPxDSv//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/ECH/xAAcEAEBAAICAwAAAAAAAAAAAAABIQARMUFRYYH/2gAIAQEAAT8QKjA2K9d5CN+Fh8MIoHMI+MBojoHrGdAjniS4lunRFtz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0ff237757982b8ae18e6bc5e1aee8001/e93cc/69873342_1143855435812336_7965630060092719104_o.webp 300w", "/static/0ff237757982b8ae18e6bc5e1aee8001/b0544/69873342_1143855435812336_7965630060092719104_o.webp 600w", "/static/0ff237757982b8ae18e6bc5e1aee8001/68fc1/69873342_1143855435812336_7965630060092719104_o.webp 1200w", "/static/0ff237757982b8ae18e6bc5e1aee8001/a2303/69873342_1143855435812336_7965630060092719104_o.webp 1800w", "/static/0ff237757982b8ae18e6bc5e1aee8001/7c4f2/69873342_1143855435812336_7965630060092719104_o.webp 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0ff237757982b8ae18e6bc5e1aee8001/73b64/69873342_1143855435812336_7965630060092719104_o.jpg 300w", "/static/0ff237757982b8ae18e6bc5e1aee8001/3ad8d/69873342_1143855435812336_7965630060092719104_o.jpg 600w", "/static/0ff237757982b8ae18e6bc5e1aee8001/6c738/69873342_1143855435812336_7965630060092719104_o.jpg 1200w", "/static/0ff237757982b8ae18e6bc5e1aee8001/8b34c/69873342_1143855435812336_7965630060092719104_o.jpg 1800w", "/static/0ff237757982b8ae18e6bc5e1aee8001/8fe94/69873342_1143855435812336_7965630060092719104_o.jpg 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0ff237757982b8ae18e6bc5e1aee8001/6c738/69873342_1143855435812336_7965630060092719104_o.jpg",
              "alt": "69873342 1143855435812336 7965630060092719104 o",
              "title": "69873342 1143855435812336 7965630060092719104 o",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgUE/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB0csTnSSgzpot82kgF//EABwQAAEEAwEAAAAAAAAAAAAAAAEAAgMREyExQf/aAAgBAQABBQJ0mpu2sjSZFa0q1ZTe+EkH/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhEP/aAAgBAwEBPwHFSn//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/Aalj/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAEQETEhQf/aAAgBAQAGPwLgjT0VRY2ZOn//xAAfEAEAAgEDBQAAAAAAAAAAAAABABFBIVFhEDFxkaH/2gAIAQEAAT8hDa8RZOhpLRiFtfUOXyVSgcS9DE3VcbpxDviAFRzP/9oADAMBAAIAAwAAABC/yI7/xAAaEQACAgMAAAAAAAAAAAAAAAAAARARMWFx/9oACAEDAQE/EKh8Fs6P/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQEXH/2gAIAQIBAT8QbhKMH//EAB4QAQADAQABBQAAAAAAAAAAAAEAESExQWFxkbHB/9oACAEBAAE/EB0OHAd9mGLS1bU7On6ECRWBpBhAKvWrHBum+Y6RvggtrWgNiShg04kub6Lr1nwcghPAtnn9n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3aff474731850af10fc71bd8c5bbd799/e93cc/16463227_611766325687919_908044534193679762_o.webp 300w", "/static/3aff474731850af10fc71bd8c5bbd799/b0544/16463227_611766325687919_908044534193679762_o.webp 600w", "/static/3aff474731850af10fc71bd8c5bbd799/6c7d1/16463227_611766325687919_908044534193679762_o.webp 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3aff474731850af10fc71bd8c5bbd799/73b64/16463227_611766325687919_908044534193679762_o.jpg 300w", "/static/3aff474731850af10fc71bd8c5bbd799/3ad8d/16463227_611766325687919_908044534193679762_o.jpg 600w", "/static/3aff474731850af10fc71bd8c5bbd799/1fe05/16463227_611766325687919_908044534193679762_o.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3aff474731850af10fc71bd8c5bbd799/1fe05/16463227_611766325687919_908044534193679762_o.jpg",
              "alt": "16463227 611766325687919 908044534193679762 o",
              "title": "16463227 611766325687919 908044534193679762 o",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      